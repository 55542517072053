import { template as template_15ef1e5698554c109674f7c2720284c0 } from "@ember/template-compiler";
const FKControlMenuContainer = template_15ef1e5698554c109674f7c2720284c0(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
