import { template as template_6bedfe83c1c64a4da09eaf24301a85e5 } from "@ember/template-compiler";
const WelcomeHeader = template_6bedfe83c1c64a4da09eaf24301a85e5(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
