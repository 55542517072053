import { template as template_349e91fc78df4a8e83ea95907fe0b808 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_349e91fc78df4a8e83ea95907fe0b808(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
