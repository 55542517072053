import { template as template_bc04955b6d7c40a09b4a44ea2ba5e298 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_bc04955b6d7c40a09b4a44ea2ba5e298(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
