import { template as template_8a6f4f3e777d42e5ba05fe97d3477244 } from "@ember/template-compiler";
const FKLabel = template_8a6f4f3e777d42e5ba05fe97d3477244(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
